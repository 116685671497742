









































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'home-organizers-cta',

  props: {
    content: {
      type: Object,
      required: true,
    },
  },
})
