import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=2e9a54d0&scoped=true"
import script from "./Section.vue?vue&type=script&lang=ts"
export * from "./Section.vue?vue&type=script&lang=ts"
import style0 from "./Section.vue?vue&type=style&index=0&id=2e9a54d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e9a54d0",
  null
  
)

export default component.exports