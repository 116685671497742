































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import HomePromo from '@/components/home/Promo.vue'

export default defineComponent({
  name: 'Subpage',
  components: {
    HomePromo,
  },
  setup() {
    const { content } = useGetters(['content'])
    const hasVisual =
      content.value.flexibleContent &&
      (content.value.flexibleContent[0]?.component === 'video' ||
        content.value.flexibleContent[0]?.component === 'picture')
    let promoLabel: string | null = null

    // Get promo label to add on hero
    // Filter flexible components to get cpts that can add anchors
    const anchorComponents =
      content.value.flexibleContent &&
      content.value.flexibleContent.filter(
        component =>
          component.component === 'highlightDuo' ||
          component.component === 'ctaPromo'
      )

    // Find the (first) one with an anchor
    const anchorComponent =
      anchorComponents && anchorComponents.find(cta => cta.content.anchor)

    if (anchorComponent) {
      promoLabel = anchorComponent.content.anchor.label
    }

    return {
      content,
      hasVisual,
      promoLabel,
    }
  },
})
