



































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ContactProject from '@/components/contact/Project.vue'

export default defineComponent({
  name: 'contact-step',

  components: {
    ContactProject,
  },

  data() {
    return {
      contentDev: {
        form: {
          project: {
            title: '<strong>Parlez-nous de votre projet</strong>',
            upload: {
              fileRecords: [],
              uploadUrl: 'https://example.com',
            },
            submit: {
              label: 'Envoyer',
              icon: 'send',
            },
            clientIdCheck: {
              label: '',
              name: '',
            },
            clientId: {
              slug: 'input',
              type: 'text',
              name: 'client',
              data: 'CL-',
              label: 'Numéros Client',
              placeholder: 'CL-',
              modifiers: 'full',
              required: true,
            },

            inputs: [
              {
                slug: 'input',
                type: 'email',
                name: 'email',
                label: 'E-mail',
                value: 'CL-',
                placeholder: 'sam@gmail.com',
                modifiers: 'full',
                required: true,
              },
              {
                slug: 'input',
                type: 'text',
                name: 'lastName',
                label: 'Nom',
                placeholder: 'Jonhson',
                required: true,
              },
              {
                slug: 'input',
                type: 'text',
                name: 'firstName',
                label: 'Prénom',
                placeholder: 'Sam',
                required: true,
              },
              {
                slug: 'input',
                type: 'tel',
                name: 'phone',
                label: 'Téléphone',
                placeholder: '+32478223251',
                modifiers: 'full',
                required: false,
              },
              {
                slug: 'textarea',
                name: 'Message',
                required: true,
                label: 'Décrivez votre projet',
              },
            ],
          },
        },
      },
    }
  },

  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const template = ctx.root.$route.name

    console.log('ctx.root.$route.name', ctx.root.$route)

    return {
      content,
      template,
    }
  },
})
