






















import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { StorageUtils } from '@/inc/plugins/storage'
import { ThirdStep } from '@/inc/store/modules/steps'

export default defineComponent({
  name: 'partner-landing',
  setup(_props, ctx) {
    const redirect = ref()
    const { $storage } = ctx.root.$options
    const { thirdStep } = useGetters('steps', ['thirdStep']) as {
      thirdStep: Ref<(storage: StorageUtils) => ThirdStep>
    }

    onMounted(() => {
      redirect.value = useGetters(['content'])

      // Check step3 or step3.accessCode instead?
      if (
        !thirdStep.value($storage!) ||
        !thirdStep.value($storage!).accessCode
      ) {
        console.log('redirect')
        ctx.root.$router.push(redirect.value.content.redirect)
      }
    })

    return {
      ...useGetters(['content']),
    }
  },
})
