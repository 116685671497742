

















































import type { FlexibleQuote as FlexibleQuoteType } from '@/inc/types'

import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { push } from '@/inc/utils'

import JobListing from '@/components/job/Listing.vue'
import FlexibleQuote from '@/components/flexible/Quote.vue'

export default defineComponent({
  name: 'Jobs',
  components: {
    JobListing,
    FlexibleQuote,
  },
  setup(_props, ctx) {
    const hasSticky = ref(true)
    const scrollTo = (selector: string) => {
      const el = document.querySelector(selector) as HTMLElement

      window.scrollTo({
        top: el?.offsetTop,
        left: 0,
        behavior: 'smooth',
      })
    }

    // Track click on spontaneous application cta
    const onCtaClick = (content: FlexibleQuoteType) => {
      push({
        event: 'job_spontaneous_cta_click',
        linkUrl: content.link?.url,
      })
    }

    onMounted(() => {
      ctx.root.$ee.$on('job-listing:visible', () => {
        hasSticky.value = false
      })
      ctx.root.$ee.$on('job-listing:hidden', () => {
        hasSticky.value = true
      })
    })

    return {
      scrollTo,
      hasSticky,
      ...useGetters(['content']),
      onCtaClick,
    }
  },
})
