










































/* eslint-disable max-len */
import VueComponent from 'vue'
import { gsap } from 'gsap'

import { HomeOrganizingOrganizer } from '@/inc/types'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'HomeOrganizingOrganizer',
  components: {},
  props: {
    content: {
      type: Object as PropType<HomeOrganizingOrganizer>,
      default: () => ({
        picture: {
          src: '/thumbor/fit-in/1024x/--/uploads/2022/03/image-11.png',
          rawUrl: 'uploads/2022/03/image-11.png',
          alt: '',
          caption: '',
          description: '',
          width: 2894,
          height: 1930,
          srcset:
            'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2022/03/image-11.png 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2022/03/image-11.png 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2022/03/image-11.png 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2022/03/image-11.png 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2022/03/image-11.png 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2022/03/image-11.png 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2022/03/image-11.png 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2022/03/image-11.png 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2022/03/image-11.png 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2022/03/image-11.png 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2022/03/image-11.png 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2022/03/image-11.png 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2022/03/image-11.png 2880w',
        },
        displayTitle: '<em>Joke</em> Himpens',
        subtitle: 'aka Practical Joke',
        episode: {
          picture: {
            src: '/thumbor/fit-in/1024x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg',
            rawUrl: 'uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg',
            alt: '',
            caption: '',
            description: '',
            width: 1707,
            height: 2560,
            srcset:
              'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2021/06/5-Camber_bureau_detail-2-scaled.jpg 2880w',
          },
          title: 'Organiser une chambre d’enfant',
          link: {
            label: 'Duo – Combien ça coute / Demande de devis',
            url: '/fr/prix-placard-sur-mesure-camber/',
            target: '',
          },
        },
      }),
    },
  },

  setup(props) {
    const root = ref<HTMLElement | null>(null)
    const name = ref<HTMLElement | null>(null)
    const picture = ref<HTMLElement | null>(null)
    const episodePicture = ref<HTMLElement | null>(null)
    const episodeLink = ref<VueComponent | null>(null)
    const isMobile = ref(true)
    let tl: ReturnType<typeof gsap.timeline> | null = null

    const setTimeline = () => {
      const factor = window.innerWidth < 960 ? 1 : 2

      tl = gsap.timeline({
        scrollTrigger: {
          id: `organizer-${props.content.subtitle}`,
          trigger: root.value!,
          start: '-10% 50%',
          end: '110%',
          scrub: 0,
        },
      })

      tl.fromTo(
        episodePicture.value,
        { y: 40 * factor },
        { y: -40 * factor, duration: 2, ease: 'slow(0.3, 0.7, false)' },
        0
      )
        .fromTo(
          episodeLink.value!.$el,
          { y: 50 * factor },
          { y: -40 * factor, duration: 2, ease: 'slow(0.3, 0.7, false)' },
          0
        )
        .fromTo(
          picture.value,
          { y: 50 * factor },
          { y: 0, duration: 2, ease: 'slow(0.3, 0.7, false)' },
          0
        )
    }

    const onResize = (w: number) => {
      const newIsMobile = w < 1024

      if (newIsMobile !== isMobile.value) {
        isMobile.value = newIsMobile

        if (tl) {
          tl.kill()

          if (ScrollTrigger.getById(`organizer-${props.content.subtitle}`)) {
            ScrollTrigger.getById(`organizer-${props.content.subtitle}`).kill()
          }
        }
        gsap.set(
          [episodePicture.value, episodeLink.value!.$el, picture.value],
          { clearProps: 'all' }
        )

        setTimeline()
      }
    }

    onMounted(() => {
      setTimeline()
    })

    return {
      root,
      name,
      picture,
      episodePicture,
      episodeLink,
      onResize,
    }
  },
})
