










import Vue, { VueConstructor } from 'vue'
import { computed, defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import Contact from '@/views/pages/Contact.vue'
import ContactOther from '@/views/pages/ContactOther.vue'
import ContactOldProject from '@/views/pages/ContactOldProject.vue'
import Faq from '@/views/pages/Faq.vue'
import Jobs from '@/views/pages/Jobs.vue'
import HomeOrganizing from '@/views/pages/HomeOrganizing.vue'
import HomeOrganizer from '@/views/pages/HomeOrganizers.vue'
import MagazineLanding from '@/views/pages/MagazineLanding.vue'
import PartnerLanding from '@/views/pages/PartnerLanding.vue'
import Personality from '@/views/pages/Personality.vue'
import PersonalityLanding from '@/views/pages/PersonalityLanding.vue'
import Process from '@/views/pages/Process.vue'
import Prices from '@/views/pages/Prices.vue'
import Subpage from '@/views/pages/Subpage.vue'

export interface Pages {
  [key: string]: VueConstructor<Vue>
}

// prettier-ignore
const pages: Pages = {
  'contact': Contact,
  'faq': Faq,
  'form-other': ContactOther,
  'form-project': ContactOldProject,
  'home-organizing-landing': HomeOrganizing,
  'home-organizer': HomeOrganizer,
  'jobs': Jobs,
  'magazine-landing': MagazineLanding,
  'partner-landing': PartnerLanding,
  'personality': Personality,
  'personality-landing': PersonalityLanding,
  'process': Process,
  'prices': Prices,
  'page': Subpage,
}

export default defineComponent({
  name: 'page',
  setup() {
    const { template } = useGetters(['template'])

    const component = computed<VueConstructor<Vue>>(
      () => pages[template.value] || Subpage
    )

    return {
      component,
      template,
    }
  },
})
