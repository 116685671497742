

































































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { ValidationObserver } from 'vee-validate'
import axios from 'axios'
import { push, GtmLayer } from '@/inc/utils'

import FormInput from '@/components/form/Input.vue'
import FormSelect from '@/components/form/Select.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormTextarea from '@/components/form/Textarea.vue'
import FormRecaptcha from '@/components/form/Recaptcha.vue'
import FormFeedback from '@/components/form/Feedback.vue'

export default defineComponent({
  name: 'contact-form',
  components: {
    FormInput,
    FormSelect,
    FormCheckbox,
    FormTextarea,
    FormRecaptcha,
    FormFeedback,
    ValidationObserver,
  },
  props: ['content'],
  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const success = ref(false)
    const el = ref()
    const select = ref()
    const error = ref(false)
    const sending = ref(false)

    const template = ctx.root.$route.name

    const onSubmit = async () => {
      sending.value = true
      const selectSubject = select.value[0].selected.value

      const { content } = useGetters(['content'])
      const formData = new FormData(form.value!)
      const { action } = props.content

      const data = {
        action,
        lang: `${ctx.root.$route.params.lang}-${process.env.VUE_APP_COUNTRY}`,
        subject: selectSubject,
        location: process.env.VUE_APP_DOMAIN,
        // Hubspot data
        hutk: ctx.root.$cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
      }

      for (const pair of formData.entries()) {
        const [name, value] = pair
        if (typeof name === 'string') {
          data[name] = value
        }
      }

      formData.append('form_id', 'other-request')

      await axios
        .post(action, data)
        .then(res => {
          sending.value = false
          success.value = true
          const layer: GtmLayer = {
            event: 'form_submit',
            contactType: 'Autres demandes',
            selectSubject,
          }
          push(layer)
          // Ctx.root.$router.push({ name: 'other-result' })
          ctx.root.$router.push(props.content.next)
          console.log('res', res)
        })
        .catch(error => {
          sending.value = false
          success.value = false
          error.value = true
          console.log('errors', error)
        })
    }

    return {
      el,
      form,
      success,
      onSubmit,
      select,
      template,
      error,
      sending,

      ...useGetters(['chrome']),
    }
  },
})
