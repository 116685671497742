














































































































import axios from 'axios'

import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

import { push, GtmLayer } from '@/inc/utils'
import { File } from '@/inc/types'

import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'
import FormInput from '@/components/form/Input.vue'
import FormRecaptcha from '@/components/form/Recaptcha.vue'
import FormSelect from '@/components/form/Select.vue'
import FormTextarea from '@/components/form/Textarea.vue'
import FormFiles from '@/components/form/Files.vue'
import FormTooltip from '@/components/form/Tooltip.vue'

export default defineComponent({
  name: 'contact-project',
  components: {
    FormCheckbox,
    FormFeedback,
    FormInput,
    FormRecaptcha,
    FormSelect,
    FormTextarea,
    FormFiles,
    FormTooltip,
    ValidationObserver,
  },
  props: ['content'],
  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const success = ref(false)
    const fileRecords = ref<File[]>([])
    const error = ref(false)
    const sending = ref(false)
    const showroomsElRef = ref()

    const updateFiles = (payload: File[]) => {
      fileRecords.value = payload
    }

    const onSubmitClick = () => {
      const el = document.querySelector('.is-invalid')

      if (!el) {
        return
      }

      const elTop = el.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elTop - 100,
        left: 0,
        behavior: 'smooth',
      })
    }

    const onSubmit = async () => {
      const { content } = useGetters(['content'])
      const formData = new FormData(form.value!)
      const { action } = props.content
      let showroom = ''

      sending.value = true

      if (showroomsElRef.value && showroomsElRef.value[0]) {
        // eslint-disable-next-line prefer-destructuring
        const { selected } = showroomsElRef.value[0]
        showroom = selected.sfValue || selected.value
      }

      const data = {
        action,
        lang: `${ctx.root.$route.params.lang}-${process.env.VUE_APP_COUNTRY}`,
        attachments: fileRecords.value.map(record => record.url),
        formId: 'old-project',
        location: process.env.VUE_APP_DOMAIN,
        showroom,
        // Hubspot data
        hutk: ctx.root.$cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
      }

      for (const pair of formData.entries()) {
        const [name, value] = pair
        if (typeof name === 'string') {
          data[name] = value
        }
      }

      await axios
        .post(action, data)
        .then(res => {
          sending.value = false
          success.value = true
          const layer: GtmLayer = {
            event: 'form_submit',
            contactType: 'Contact Project',
          }
          push(layer)
          ctx.root.$router.push(props.content.next)
          console.log(res)
        })
        .catch(error => {
          sending.value = false
          success.value = false
          error.value = true
          console.log(error)
          console.log('errors', formData)
        })
    }

    return {
      form,
      showroomsElRef,
      success,
      fileRecords,
      updateFiles,
      error,
      sending,
      onSubmit,
      onSubmitClick,
      ...useGetters(['chrome']),
    }
  },
})
