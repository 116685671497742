








































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import PricesServices from '@/components/prices/Services.vue'
import PricesExamples from '@/components/prices/Examples.vue'
import FlexibleSlider from '@/components/flexible/Slider.vue'
import FlexibleKeyList from '@/components/flexible/KeyList.vue'
import CtaPreEncoded from '@/components/cta/PreEncoded.vue'

export default defineComponent({
  name: 'Prices',
  components: {
    PricesServices,
    PricesExamples,
    FlexibleSlider,
    FlexibleKeyList,
    CtaPreEncoded,
  },
  setup() {
    const infoIcons = {
      price: 'euro',
      architect: 'pencil-ruler',
      worker: 'hammer',
    }

    return {
      ...useGetters(['content']),
      infoIcons,
    }
  },
})
