

















/* eslint-disable max-len */
import { PersonalityDuo } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'PersonalityDuo',
  components: {},
  props: {
    content: {
      type: Object as PropType<PersonalityDuo>,
      default: () => ({
        picture: {
          src: 'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1024x/--/uploads/2022/03/personality-daniela-duo.png',
          rawUrl: 'uploads/2022/03/personality-daniela-duo.png',
          alt: '',
          caption: '',
          description: '',
          width: 908,
          height: 1076,
          srcset:
            'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2022/03/personality-daniela-duo.png 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2022/03/personality-daniela-duo.png 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2022/03/personality-daniela-duo.png 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2022/03/personality-daniela-duo.png 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2022/03/personality-daniela-duo.png 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2022/03/personality-daniela-duo.png 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2022/03/personality-daniela-duo.png 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2022/03/personality-daniela-duo.png 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2022/03/personality-daniela-duo.png 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2022/03/personality-daniela-duo.png 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2022/03/personality-daniela-duo.png 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2022/03/personality-daniela-duo.png 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2022/03/personality-daniela-duo.png 2880w',
        },
        displayTitle: '<em>Daniela</em><br>Prepeliuc',
        htmltext:
          '<p>Misschien ken je <strong>Daniela Prepeliuc</strong> wel? Door haar carrière als <strong>tv-presentatrice</strong> op RTBF en TV5 Monde is ze erg bekend in Franstalig België. Maar misschien ken je haar ook van <strong>Instagram</strong>, waar ze haar trouwe volgers graag een kijk geeft in haar dagelijkse leven.<br />\nOm haar leven, zowel professioneel als privé, gemakkelijker en meer ontspannen te laten verlopen, heeft ze Camber ingeschakeld. Samen met Daniela geven we haar <strong>home office</strong> een praktische metamorfose.</p>\n',
        link: {
          label: 'Daniela Prepeliuc',
          url: '/fr/personality/daniela-prepeliuc/',
          target: '',
        },
      }),
    },
  },

  setup() {
    return {}
  },
})
