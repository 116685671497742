var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{ref:"el",staticClass:"contact-form"},[_c('h2',{staticClass:"contact-form__title h3",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('ValidationObserver',{attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var touched = ref.touched;
var invalid = ref.invalid;
return [_c('form',{ref:"form",staticClass:"form",attrs:{"method":"POST","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"form-inner"},[_c('fieldset',{staticClass:"form__fieldset"},_vm._l((_vm.content.inputs),function(item,index){return _c('div',{key:("form-input-" + index),staticClass:"form__input",class:((item.slug) + " " + (item.modifiers))},[(item.slug == 'input')?_c('form-input',{attrs:{"type":item.type,"name":item.name,"label":item.label,"placeholder":item.placeholder,"required":item.required}}):_vm._e(),(item.slug == 'select')?_c('form-select',{ref:"select",refInFor:true,attrs:{"name":item.name,"options":item.options,"label":item.label,"required":item.required,"placeholder":item.placeholder}}):_vm._e(),(item.slug == 'textarea')?_c('form-textarea',{attrs:{"name":item.name,"label":item.label,"required":item.required}}):_vm._e()],1)}),0),_c('fieldset',{staticClass:"form__confirmation m-m"},[_vm._l((_vm.content.inputs),function(item,index){return _c('div',{key:("form-checkbox-" + index)},[(item.slug == 'checkbox')?_c('form-checkbox',{class:((item.slug) + " " + (item.modifiers)),attrs:{"name":item.name,"required":item.required}},[_c('span',{domProps:{"innerHTML":_vm._s(item.label)}}),(item.required)?_c('span',{staticClass:"form-label__required"},[_vm._v("*")]):_vm._e()]):_vm._e()],1)}),_c('form-recaptcha',{staticClass:"form__confirmation__recaptcha",attrs:{"sitekey":_vm.chrome.recaptchaKey}})],2),_c('fieldset',{staticClass:"form__send"},[_c('g-action',{staticClass:"submit__btn",class:{ disabled: invalid, sending: _vm.sending },attrs:{"content":{
              label: _vm.content.submit.label,
              icon: _vm.content.submit.icon,
              modifiers: ['filled', 'btn'],
              type: 'submit',
              tag: 'button',
            },"prevent-tracking":true}}),(touched && invalid)?_c('form-feedback',{staticClass:"form__feedback",attrs:{"content":"form-incomplete"}}):_vm._e(),(_vm.error)?_c('form-feedback',{staticClass:"form__feedback",attrs:{"content":"form-error"}}):_vm._e()],1)])])]}}],null,false,1346013776)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }