var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"root",staticClass:"home-organizers-cta wrapper",class:{ alternate: _vm.content.alternate }},[_c('GPicture',{staticClass:"picture",attrs:{"cover":false,"content":_vm.content.picture,"sets":[
      '240',
      '320',
      '480',
      '640',
      '960',
      '1280',
      '1600',
      '1920',
      '2240' ]}}),_c('div',{staticClass:"content"},[_c('g-title',{staticClass:"h3--bold g-label headline",attrs:{"content":_vm.content.headline,"tag":"h3"}}),_c('g-title',{staticClass:"title",attrs:{"content":_vm.content.title,"tag":"h1"}}),_c('GAction',{staticClass:"button",attrs:{"content":Object.assign({}, _vm.content.link,
        {icon: 'play',
        modifiers: ['btn', 'filled', 'reverse']})}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }