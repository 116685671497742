



































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ContactForm from '@/components/contact/OtherForm.vue'

export default defineComponent({
  name: 'contact-step',

  components: {
    ContactForm,
  },

  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const template = ctx.root.$route.name

    console.log('ctx.root.$route.name', ctx.root.$route)

    return {
      content,
      template,
    }
  },
})
