































import type { Picture } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'

interface MagazineLandingHero {
  title: string
  text: string
  scrollLabel: string
  picture: Picture
}

export default defineComponent({
  name: 'MagazineLandingHero',
  components: {},
  props: {
    content: {
      type: Object as PropType<MagazineLandingHero>,
      required: true,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)

    const scrollToArticles = () => {
      if (!rootElRef.value) {
        return
      }

      const { bottom } = rootElRef.value.getBoundingClientRect()

      window.scrollTo({ top: bottom, left: 0, behavior: 'smooth' })
    }

    return {
      rootElRef,
      scrollToArticles,
    }
  },
})
