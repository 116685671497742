




















































import { gsap, Power4 } from 'gsap'

import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

export default defineComponent({
  name: 'HomeOrganizingHero',
  components: {},
  setup(_props, ctx) {
    const root = ref<HTMLElement | null>(null)
    const title = ref<HTMLElement | null>(null)
    const subtitle = ref<HTMLElement | null>(null)
    const intro = ref<HTMLElement | null>(null)
    const galleryTitle = ref<HTMLElement | null>(null)
    const galleryText = ref<HTMLElement | null>(null)
    const pictures = ref<HTMLElement[] | null>(null)
    const isMobile = ref(true)
    let scrollTl: ReturnType<typeof gsap.timeline> | null = null

    /* eslint-disable indent */
    const onboardingParams = () =>
      isMobile.value
        ? [
            { rotate: -54, x: '-40vw', scale: 1.4 },
            { rotate: 20, x: '40vw', scale: 1.3 },
            { rotate: 7, x: '-40vw', scale: 1.3 },
            { rotate: 64, x: '40vw', scale: 1.3 },
          ]
        : [
            { rotate: -54, x: '-40vw', y: '-100vh', scale: 1.4 },
            { rotate: 20, x: '40vw', y: '-120vh', scale: 2.2 },
            { rotate: 7, x: '-40vw', y: '-30vh', scale: 1.3 },
            { rotate: 64, y: '0', scale: 1.3 },
          ]

    const scrollParams = () =>
      isMobile.value
        ? [
            { rotate: -27, x: '-40vw', scale: 1.2 },
            { rotate: 56, x: '40vw', scale: 1.2 },
            { rotate: -77, scale: 1.1 },
            { rotate: 10, x: '40vw', scale: 1.1 },
          ]
        : [
            { rotate: -27, x: '-5vw', y: '-85vh', scale: 1.2 },
            { rotate: 56, x: '35vw', y: '-50vh', scale: 2 },
            { rotate: -77, x: '-20vw', y: '-70vh', scale: 1.1 },
            { rotate: 10, y: '-30vh', scale: 1.1 },
          ]
    /* eslint-enable indent */

    const onboard = () => {
      const tl = gsap.timeline({
        delay: 1,
        onStart: () => {
          document.body.classList.add('no-scroll')
          root.value!.classList.remove('before-onboard')
          setScrollTimeline()
        },
        onComplete: () => {
          document.body.classList.remove('no-scroll')
        },
      })

      tl.set([galleryTitle.value, galleryText.value], { opacity: 0 })

      pictures.value!.forEach((pic: Element, index: number) => {
        tl.fromTo(
          pic,
          { ...onboardingParams()[index], opacity: 0 },
          {
            ...scrollParams()[index],
            opacity: 1,
            duration: 2,
            ease: Power4.easeOut,
          },
          0
        )
      })

      tl.from(
        [title.value, subtitle.value, intro.value],
        {
          y: 50,
          opacity: 0,
          duration: 1.5,
          stagger: 0.05,
          ease: Power4.easeOut,
        },
        0.15
      )
    }

    const setScrollTimeline = () => {
      scrollTl = gsap.timeline({
        scrollTrigger: {
          id: 'hero',
          trigger: root.value!,
          start: 'top',
          end: '35%',
          scrub: 1,
        },
      })

      scrollTl
        .add('start')
        .to([title.value, subtitle.value, intro.value], {
          y: -20,
          opacity: 0,
          duration: 2,
          ease: Power4.easeIn,
        })
        .to([galleryTitle.value, galleryText.value], { opacity: 1 }, 0)

      pictures.value!.forEach((pic: Element, index: number) => {
        scrollTl!.fromTo(
          pic,
          { ...scrollParams()[index] },
          {
            rotate: 0,
            x: 0,
            y: 0,
            scale: 1,
            duration: 2,
          },
          0.5
        )
      })

      scrollTl.add('end')
    }

    const onResize = (w: number) => {
      const newIsMobile = w < 1024

      if (newIsMobile !== isMobile.value) {
        isMobile.value = newIsMobile

        if (scrollTl) {
          scrollTl.kill()

          if (ScrollTrigger.getById('hero')) {
            ScrollTrigger.getById('hero').kill()
          }
        }
        gsap.set(
          [
            title.value,
            subtitle.value,
            intro.value,
            pictures.value,
            galleryTitle.value,
            galleryText.value,
          ],
          { clearProps: 'all' }
        )
        setScrollTimeline()
      }
    }

    onMounted(() => {
      isMobile.value = window.innerWidth < 1024

      ctx.root.$once('page:ready', () => {
        onboard()
      })
    })

    return {
      isMobile,
      ...useGetters(['content']),
      root,
      title,
      subtitle,
      intro,
      galleryTitle,
      galleryText,
      pictures,
      onResize,
    }
  },
})
