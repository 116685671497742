var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{ref:"root",staticClass:"home-organizers-hero"},[_c('div',{staticClass:"picture-outer"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(_vm.content.picture),expression:"content.picture"}],staticClass:"picture",attrs:{"sets":[
        '240',
        '320',
        '480',
        '640',
        '960',
        '1280',
        '1600',
        '1920',
        '2240' ]}})]),_c('div',{staticClass:"content wrapper"},[_c('g-title',{staticClass:"title",attrs:{"content":_vm.content.title || _vm.content.displayTitle,"tag":"h1"}}),(_vm.content.htmltext)?_c('g-intro',{staticClass:"text",attrs:{"content":_vm.content.htmltext}}):_vm._e(),(_vm.content.video && _vm.content.video.youtubeId)?_c('button',{staticClass:"button",attrs:{"aria-label":_vm.$t('watch')},on:{"click":_vm.openVideo}},[_c('span',{staticClass:"button__icon"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"icon",attrs:{"symbol":"ui-play","size":"0 0 30 30"}})]),_c('span',{staticClass:"button__label"},[_vm._v(_vm._s(_vm.$t('watch')))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }