































/* eslint-disable max-len */
import { gsap, Power4 } from 'gsap'

import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

import { PersonalityHero } from '@/inc/types'

export default defineComponent({
  name: 'PersonalityHero',
  components: {},
  props: {
    content: {
      type: Object as PropType<PersonalityHero>,
      default: () => ({
        mobilePicture: {
          src: 'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1024x/--/uploads/2022/03/personality-hero-mobile.png',
          rawUrl: 'uploads/2022/03/personality-hero-mobile.png',
          alt: '',
          caption: '',
          description: '',
          width: 2894,
          height: 1930,
          srcset:
            'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2022/03/personality-hero-mobile.png 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2022/03/personality-hero-mobile.png 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2022/03/personality-hero-mobile.png 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2022/03/personality-hero-mobile.png 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2022/03/personality-hero-mobile.png 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2022/03/personality-hero-mobile.png 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2022/03/personality-hero-mobile.png 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2022/03/personality-hero-mobile.png 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2022/03/personality-hero-mobile.png 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2022/03/personality-hero-mobile.png 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2022/03/personality-hero-mobile.png 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2022/03/personality-hero-mobile.png 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2022/03/personality-hero-mobile.png 2880w',
        },
        leftPicture: {
          src: 'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1024x/--/uploads/2022/03/personality-landing-daniela.png',
          rawUrl: 'uploads/2022/03/personality-landing-daniela.png',
          alt: '',
          caption: '',
          description: '',
          width: 2894,
          height: 1930,
          srcset:
            'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2022/03/personality-landing-daniela.png 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2022/03/personality-landing-daniela.png 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2022/03/personality-landing-daniela.png 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2022/03/personality-landing-daniela.png 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2022/03/personality-landing-daniela.png 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2022/03/personality-landing-daniela.png 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2022/03/personality-landing-daniela.png 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2022/03/personality-landing-daniela.png 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2022/03/personality-landing-daniela.png 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2022/03/personality-landing-daniela.png 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2022/03/personality-landing-daniela.png 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2022/03/personality-landing-daniela.png 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2022/03/personality-landing-daniela.png 2880w',
        },
        rightPicture: {
          src: 'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1024x/--/uploads/2022/03/personality-landing-sarah-big.png',
          rawUrl: 'uploads/2022/03/personality-landing-sarah-big.png',
          alt: '',
          caption: '',
          description: '',
          width: 2894,
          height: 1930,
          srcset:
            'https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/160x/--/uploads/2022/03/personality-landing-sarah-big.png 160w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/240x/--/uploads/2022/03/personality-landing-sarah-big.png 240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/320x/--/uploads/2022/03/personality-landing-sarah-big.png 320w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/480x/--/uploads/2022/03/personality-landing-sarah-big.png 480w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/640x/--/uploads/2022/03/personality-landing-sarah-big.png 640w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/960x/--/uploads/2022/03/personality-landing-sarah-big.png 960w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1280x/--/uploads/2022/03/personality-landing-sarah-big.png 1280w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1440x/--/uploads/2022/03/personality-landing-sarah-big.png 1440w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1600x/--/uploads/2022/03/personality-landing-sarah-big.png 1600w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/1920x/--/uploads/2022/03/personality-landing-sarah-big.png 1920w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2240x/--/uploads/2022/03/personality-landing-sarah-big.png 2240w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2560x/--/uploads/2022/03/personality-landing-sarah-big.png 2560w, https://camber-website-v1-cms.staging.epic-sys.io/thumbor/fit-in/2880x/--/uploads/2022/03/personality-landing-sarah-big.png 2880w',
        },
        displayTitle:
          'Volg<br><em>Daniela Prepeliuc</em> &amp;<br><em>Sarah Nelissen</em> in hun<br>Camber-avontuur',
        intro:
          'Maak kennis met <strong>Daniela & Sarah</strong>, twee bekende hippe mama’s die een beroep doen op de expertise van Camber. Op onze website kan je het hele proces van hun droomproject, van start tot einde, meevolgen. ',
      }),
    },
  },

  setup(_props, ctx) {
    const root = ref<HTMLElement | null>(null)
    const isMobile = ref(true)

    const onboard = () => {
      root.value!.classList.remove('before-onboard')

      const title = root.value!.querySelector('.personality-hero__title')
      const text = root.value!.querySelector('.personality-hero__text')
      const tl = gsap.timeline()

      tl.from(
        [title, text],
        {
          y: 50,
          opacity: 0,
          duration: 1.5,
          stagger: 0.1,
          ease: Power4.easeOut,
        },
        0.3
      )

      if (isMobile.value) {
        const mobilePicture = root.value!.querySelector(
          '.personality-hero__picture'
        )

        tl.from(
          mobilePicture,
          {
            opacity: 0,
            duration: 1.5,
            ease: Power4.easeOut,
          },
          0.5
        )
      } else {
        const pictures = root.value!.querySelectorAll(
          '.personality-hero__pictures__inner'
        )
        pictures.forEach((picture: Element, i: number) => {
          tl.from(
            picture,
            {
              opacity: 0,
              duration: 2,
              xPercent: i === 0 ? -15 : 15,
              ease: 'power1.easeOut',
            },
            0.5
          )
        })
      }
    }

    const onResize = (w: number) => (isMobile.value = w < 768)

    onMounted(() => {
      isMobile.value = window.innerWidth < 768

      ctx.root.$once('page:ready', () => {
        onboard()
      })
    })

    return {
      root,
      isMobile,
      onResize,
    }
  },
})
