










































import { defineComponent } from '@vue/composition-api'
import { ContactCard } from '@/inc/types'

import Card from '@/components/contact/Card.vue'

export default defineComponent({
  name: 'contact-grid',
  props: {
    content: {
      type: Array as () => ContactCard[],
      default: () => [],
    },
  },

  components: {
    Card,
  },
})
