






































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import FlexibleVideo from '@/components/flexible/Video.vue'
import ProcessNav from '@/components/process/Nav.vue'
import ProcessSteps from '@/components/process/Steps.vue'

export default defineComponent({
  name: 'Process',
  components: {
    FlexibleVideo,
    ProcessNav,
    ProcessSteps,
  },
  setup() {
    const currentIndex = ref(0)
    const isMobile = ref(false)
    const breakpoint = 1024

    const onIndexChange = (i: number) => (currentIndex.value = i)

    const onResize = (x: number) => (isMobile.value = x < breakpoint)

    onMounted(() => {
      isMobile.value = window.innerWidth < breakpoint
    })

    return {
      ...useGetters(['content', 'chrome']),
      isMobile,
      currentIndex,
      onIndexChange,
      onResize,
    }
  },
})
