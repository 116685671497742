


































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'ProcessStep',
  props: {
    content: {
      type: Object,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const rootRef = ref<HTMLElement | null>(null)
    const pictureRef = ref<HTMLElement | null>(null)
    let isScrollingDown = true
    let savedY = 0

    const onScroll = (x: number, y: number) => {
      isScrollingDown = savedY < y
      savedY = y
    }

    const onAppear = (
      isInViewport: boolean,
      isFullyInViewport: boolean,
      position: string
    ) => {
      const acceptedPosition = isScrollingDown ? 'bottom' : 'top'

      if (!props.current && isInViewport && position === acceptedPosition) {
        ctx.emit('step:center')
      }
    }

    return {
      rootRef,
      pictureRef,
      onAppear,
      onScroll,
    }
  },
})
